import { reactive } from '@vue/composition-api'
import _ from 'lodash'

interface VerifyCodeParams {
    counVal: string
    countDown: number
    loading: boolean
    key: string
    [key: string]: any
}

export const useVerifyCode = () => {
    const verifyCodeObj: VerifyCodeParams = reactive({
        key: '',
        loading: false,
        counVal: '',
        countDown: 60
    })

    let verifyCodeObjCopy = {
        key: '',
        loading: false,
        counVal: '',
        countDown: 60
    }

    const setVerifyCodeObj = (object: VerifyCodeParams) => {
        verifyCodeObjCopy = _.cloneDeep(object)
        Object.keys(verifyCodeObj).forEach((key) => {
            verifyCodeObj[key] = object[key]
        })
        const sendTime = localStorage.getItem(verifyCodeObj.key)
        if (sendTime) {
            startSendVerifyCode()
        }
    }

    const startSendVerifyCode = () => {
        const startTime = localStorage.getItem(verifyCodeObj.key) as unknown as number
        const nowTime = new Date().getTime()
        if (startTime) {
            const surplus = verifyCodeObjCopy.countDown - parseInt(String((nowTime - startTime) / 1000), 10)
            verifyCodeObj.countDown = surplus <= 0 ? 0 : surplus
        } else {
            verifyCodeObj.countDown = verifyCodeObjCopy.countDown
            localStorage.setItem(verifyCodeObj.key, String(nowTime))
        }
        countdown()
    }

    const countdown = () => {
        if (verifyCodeObj.countDown === 0) {
            verifyCodeObj.counVal = verifyCodeObjCopy.counVal
            verifyCodeObj.countDown = verifyCodeObjCopy.countDown
            verifyCodeObj.loading = verifyCodeObjCopy.loading
            localStorage.removeItem(verifyCodeObj.key)
        } else {
            verifyCodeObj.counVal = String(verifyCodeObj.countDown)
            verifyCodeObj.countDown--
            verifyCodeObj.loading = true
            setTimeout(() => countdown(), 1000)
        }
    }



    return {
        verifyCodeObj,
        setVerifyCodeObj,
        startSendVerifyCode
    }
}
