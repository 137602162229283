






























import { findPasswordApi } from '@/api/user'
import { showError } from '@/utils/common'
import { defineComponent, reactive, ref } from '@vue/composition-api'
import { Form, FormItem } from 'element-ui'
import Main from '@/components/Main.vue'
import { useVerifyCode } from '@/hooks/useVerifyCode'
export default defineComponent({
    components: {
        Main,
        [Form.name]: Form,
        [FormItem.name]: FormItem
    },
    setup(props, { root }) {
        const { verifyCodeObj, setVerifyCodeObj, startSendVerifyCode } = useVerifyCode()
        setVerifyCodeObj({
            key: `password_find`,
            loading: false,
            counVal: '',
            countDown: 60
        })
        const formInfo = reactive({
            email: ''
        })
        const formRules = {
            email: [
                { required: true, message: root.$i18n.t('passwordFind.formRule1'), trigger: 'blur' },
                { type: 'email', message: root.$i18n.t('passwordFind.formRule2'), trigger: ['blur', 'change'] }
            ]
        }
        const formRef = ref()
        const submitLoad = ref(false)
        const submitForm = () => {
            formRef.value
                .validate()
                .then(async () => {
                    submitLoad.value = true
                    try {
                        await findPasswordApi({
                            email: formInfo.email
                        })
                        startSendVerifyCode()
                        root.$message.success(<string>root.$i18n.t('passwordFind.sendEmailMsg3', { email: formInfo.email }))
                    } catch (error) {
                        showError(error)
                    }
                    submitLoad.value = false
                })
                .catch(() => {})
        }
        return {
            formInfo,
            formRules,
            formRef,
            submitLoad,
            submitForm,
            verifyCodeObj
        }
    }
})
