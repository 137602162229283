











import { dateFormat } from '@/utils/filters'
import { defineComponent, ref } from '@vue/composition-api'

export default defineComponent({
    setup(props, { root }) {
        const companyMsg = ref(`© ${root.$t('company')}${dateFormat(new Date(), 'YYYY')}`)
        return {
            companyMsg
        }
    }
})
